import { debounce } from '@/helpers/debounce';
import { BreakPoint } from '@/hooks/useBreakPoint/consts';
import {
  MAIN_HEADER_SEARCH_COLLAPSIBLE,
  PREVENT_DISMISS_PROP,
} from '@/hooks/useHeader/consts';
import useIsMobile from '@/hooks/useIsMobile/useIsMobile';
import useNavigate from '@/hooks/useNavigate/useNavigate';
import { useSafeLayoutEffect } from '@/hooks/useSafeLayoutEffect/useSafeLayoutEffect';
import useSearchSuggestions from '@/hooks/useSearchSuggestions/useSearchSuggestions';
import Clickable from '@uikit/components/Clickable/Clickable';
import { ClickableAction } from '@uikit/components/Clickable/consts';
import Icon from '@uikit/components/Icon/Icon';
import LockBodyScroll from '@uikit/components/LockBodyScroll/LockBodyScroll';
import clsx from 'clsx';
import { CSSProperties, ChangeEvent, KeyboardEvent, useRef, useState } from 'react';
import MainHeader from '../MainHeader/MainHeader';
import styles from './SearchAddon.module.scss';
import { SearchAddonProps } from './types';

const SearchAddon = ({
  isCollapsibleActive,
  isHidden = false,
  isInverted = false,
  onCollapsibleToggle,
  quickLinks = [],
  suggestionsBoxMobileOffset = 0,
  suggestionsBoxOffset = 0,
}: SearchAddonProps) => {
  const [query, setQuery] = useState('');
  const { data: suggestions } = useSearchSuggestions(query);
  const inputRef = useRef<HTMLInputElement>(null);
  const isMobile = useIsMobile(BreakPoint.LG);

  const handleQueryChange = debounce(
    (event: ChangeEvent<HTMLInputElement>) =>
      setQuery(event.target.value.trim()),
    500
  );

  const handleQueryKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      navigateToResults((event.target as HTMLInputElement).value);
    }
  };

  const navigate = useNavigate();
  const navigateToResults = (query: string) => {
    navigate(`/suche?s=${query}&f=global`);
  };

  const toggleSearch = () =>
    onCollapsibleToggle(MAIN_HEADER_SEARCH_COLLAPSIBLE);

  const showQuickLinks = !query;
  const showSuggestions =
    isMobile || ((query ? suggestions : quickLinks)?.length || 0) > 0;

  const suggestionsBoxStyle = {
    '--mobile-offset': `${suggestionsBoxMobileOffset}px`,
    '--offset': `${suggestionsBoxOffset}px`,
  } as CSSProperties;

  useSafeLayoutEffect(() => {
    if (isCollapsibleActive) {
      inputRef.current?.focus();
    }
  }, [isCollapsibleActive]);

  return (
    <>
      {isCollapsibleActive && <LockBodyScroll />}

      <MainHeader.Addon
        aria-controls="main-header-search-bar"
        aria-hidden={isHidden}
        icon="action/search"
        isHidden={isHidden}
        isInverted={isInverted}
        onClick={toggleSearch}
        title="Suche"
        {...PREVENT_DISMISS_PROP}
      />

      <div
        aria-hidden={!isCollapsibleActive}
        className={clsx(styles.base, {
          [styles.isActive]: isCollapsibleActive,
          [styles.isInverted]: isInverted,
        })}
        id="main-header-search-bar"
      >
        <div aria-hidden="true" className={styles.flexShifter} />

        <div className={styles.searchBar} {...PREVENT_DISMISS_PROP}>
          <Icon isOutlined size="iconSize20" variant="action/basic-search" />
          <input
            autoFocus={isCollapsibleActive}
            className={styles.searchInput}
            onChange={handleQueryChange}
            onKeyUp={handleQueryKeyUp}
            placeholder="Suche"
            ref={inputRef}
            tabIndex={isCollapsibleActive ? 0 : -1}
            type="search"
          />
        </div>

        <button
          aria-controls="main-header-search-bar"
          aria-label="Suche schließen"
          className={styles.button}
          onClick={toggleSearch}
          tabIndex={isCollapsibleActive ? 0 : -1}
          {...PREVENT_DISMISS_PROP}
        >
          <Icon
            aria-hidden="true"
            size={isMobile ? 'iconSize20' : 'iconSize24'}
            variant="action/close"
          />
        </button>
      </div>

      {showSuggestions && (
        <aside
          className={clsx(styles.suggestionsBox, {
            [styles.isActive]: isCollapsibleActive,
          })}
          style={suggestionsBoxStyle}
          {...PREVENT_DISMISS_PROP}
        >
          <div className={styles.suggestionsTitle}>
            {showQuickLinks ? 'Quick Links' : 'Vorschläge'}
          </div>
          <ul className={styles.suggestionsList}>
            {showQuickLinks
              ? quickLinks?.map((quickLink) => (
                  <li key={quickLink.displayText}>
                    <Clickable
                      actionType={ClickableAction.Linkextern}
                      className={styles.suggestion}
                      clickContext="Interne Suche (Website) Quick-Tipp"
                      linkextern={quickLink.url}
                      tabIndex={isCollapsibleActive ? 0 : -1}
                    >
                      {quickLink.displayText}
                    </Clickable>
                  </li>
                ))
              : suggestions?.map((suggestion) => (
                  <li
                    className={styles.suggestion}
                    key={suggestion}
                    onClick={() => navigateToResults(suggestion)}
                    tabIndex={isCollapsibleActive ? 0 : -1}
                  >
                    {suggestion}
                  </li>
                ))}
          </ul>
        </aside>
      )}
    </>
  );
};

export default SearchAddon;
