import { CollapsibleOptions, CollapsibleTransition } from './types';

export const MAIN_HEADER_COLLAPSIBLE = Symbol();
export const MAIN_HEADER_MOBILE_COLLAPSIBLE = Symbol();
export const MAIN_HEADER_SEARCH_COLLAPSIBLE = Symbol();
export const MAIN_HEADER_SESSION_COLLAPSIBLE = Symbol();
export const PAGE_HEADER_COLLAPSIBLE = Symbol();

export const COLLAPSIBLE_OPTIONS: Record<symbol, CollapsibleOptions> = {
  [MAIN_HEADER_MOBILE_COLLAPSIBLE]: ({ isMobile }) => ({
    needsMainHeaderBackground: isMobile,
  }),
  [MAIN_HEADER_SEARCH_COLLAPSIBLE]: ({ isMobile }) => ({
    needsMainHeaderBackground: isMobile,
  }),
  [MAIN_HEADER_SESSION_COLLAPSIBLE]: ({ isMobile }) => ({
    duration: 500,
    needsMainHeaderBackground: isMobile,
  }),
  [PAGE_HEADER_COLLAPSIBLE]: ({ isMobile }) => ({
    duration: 500,
    needsMainHeaderBackground: isMobile,
    needsPageHeaderBackground: isMobile,
  }),
};

export const COLLAPSIBLE_TRANSITIONS: Record<
  symbol,
  Record<symbol, CollapsibleTransition>
> = {
  [MAIN_HEADER_COLLAPSIBLE]: {
    [MAIN_HEADER_SEARCH_COLLAPSIBLE]: () => ({ delay: 500 }),
  },
  [MAIN_HEADER_MOBILE_COLLAPSIBLE]: {
    [MAIN_HEADER_SEARCH_COLLAPSIBLE]: () => ({ delay: 500 }),
    [MAIN_HEADER_SESSION_COLLAPSIBLE]: () => ({ delay: 500 }),
  },
  [MAIN_HEADER_SEARCH_COLLAPSIBLE]: {},
  [MAIN_HEADER_SESSION_COLLAPSIBLE]: {
    [MAIN_HEADER_COLLAPSIBLE]: () => ({ delay: 500 }),
    [MAIN_HEADER_MOBILE_COLLAPSIBLE]: () => ({ delay: 500 }),
    [MAIN_HEADER_SEARCH_COLLAPSIBLE]: ({ isMobile }) => ({
      delay: isMobile ? 500 : 0,
    }),
  },
  [PAGE_HEADER_COLLAPSIBLE]: {
    [MAIN_HEADER_COLLAPSIBLE]: () => ({ delay: 500 }),
    [MAIN_HEADER_MOBILE_COLLAPSIBLE]: () => ({ delay: 500 }),
    [MAIN_HEADER_SEARCH_COLLAPSIBLE]: ({ isMobile }) => ({
      delay: isMobile ? 500 : 0,
    }),
    [MAIN_HEADER_SESSION_COLLAPSIBLE]: ({ isMobile }) => ({
      delay: isMobile ? 500 : 0,
    }),
  },
};

export const DEFAULT_OPTIONS: CollapsibleOptions = () => ({
  duration: 0,
  needsMainHeaderBackground: false,
  needsPageHeaderBackground: false,
});

export const DEFAULT_TRANSITION: CollapsibleTransition = () => ({
  delay: 0,
});

export const PREVENT_DISMISS_ATTRIBUTE = 'data-header-prevent-dismiss';
export const PREVENT_DISMISS_PROP = { [PREVENT_DISMISS_ATTRIBUTE]: true };
