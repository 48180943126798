import { logger } from '@/helpers/logger';
import useOnScreen from '@/hooks/useOnScreen/useOnScreen';
import {
  AnimationEventCallback,
  AnimationItem,
  BMEnterFrameEvent,
} from 'lottie-web';
import { useEffect, useRef, useState } from 'react';
import {
  EnterFrameCallbackEvent,
  LazilyLoadedLottie,
  LottieProps,
} from './interfaces';

const Lottie = ({
  autoplay = false,
  loop = false,
  name,
  animationData,
  className,
  playing,
  pauseMode = 'reset',
  targetFrame,
  speed = 1,
  startInView = false,
  parentRef,
  onEnterFrame,
  onLoaded,
}: LottieProps) => {
  const [lottie, setLottie] = useState<LazilyLoadedLottie>();
  const [animationItem, setAnimationItem] = useState<AnimationItem>();
  const ref = useRef<HTMLDivElement>(null);
  const isInView = useOnScreen(parentRef);

  /*
  On initial mount, lazily load lottie-web.
  We don't want it to end up in the main bundle due to its large size. Instead,
  we load when we need it (which is the first time this component is used
  anywhere in the app.)
  */
  useEffect(() => {
    if (animationData) {
      (async () => {
        try {
          const lottiePackage = await import(
            /* webpackChunkName: "lottie-web" */ 'lottie-web'
          );
          // for some reason, in the production build, import() returns an async function here
          // that we must call again to get the lottie package
          if (typeof lottiePackage === 'function') {
            const lottieFactory = await (lottiePackage as any)();
            setLottie(lottieFactory.default);
          } else {
            // in the dev server, it returns the lottie package itself
            setLottie(lottiePackage.default);
          }
        } catch (error) {
          logger.warn(
            error,
            'Failed lazy load of lottie-web, will not be able to play lottie animations'
          );
        }
      })();
    }
  }, [animationData]);

  useEffect(() => {
    if (lottie && ref && ref.current) {
      const anim = lottie.loadAnimation({
        container: ref.current,
        loop,
        autoplay,
        name,
        animationData,
      });

      setAnimationItem(anim);
    }
  }, [lottie, animationData, autoplay, loop, name, ref]);

  useEffect(() => {
    return () => {
      animationItem?.destroy();
    };
  }, [animationItem]);

  useEffect(() => {
    if (onLoaded && animationItem) {
      onLoaded();
    }
  }, [animationItem, onLoaded]);

  useEffect(() => {
    if (animationItem && typeof onEnterFrame === 'function') {
      const listener: AnimationEventCallback<BMEnterFrameEvent> = ($event) => {
        onEnterFrame($event, animationItem);
        return $event.direction;
      };

      animationItem.addEventListener('enterFrame', listener);

      return () => {
        if (animationItem) {
          try {
            animationItem.removeEventListener('enterFrame', listener);
          } catch {}
        }
      };
    }
  }, [animationItem, onEnterFrame]);

  useEffect(() => {
    if (animationItem) {
      if (playing) {
        animationItem.play();
      } else if (playing === false) {
        switch (pauseMode) {
          case 'pause':
            animationItem.pause();
            break;

          case 'finishAndPause':
            animationItem.addEventListener('loopComplete', () =>
              animationItem.pause()
            );
            break;

          case 'reset':
          default:
            animationItem.stop();
            break;
        }
      }
    }
  }, [playing, animationItem, pauseMode]);

  useEffect(() => {
    if (animationItem) {
      animationItem.setSpeed(speed);
    }
  }, [speed, animationItem]);

  useEffect(() => {
    if (typeof targetFrame === 'number' && animationItem) {
      const { timeCompleted, currentFrame } = animationItem as any;

      let forwardSteps = 0;
      let forwardCurrent = Math.round(currentFrame);
      while (forwardCurrent !== targetFrame) {
        if (forwardCurrent === timeCompleted) {
          forwardCurrent = 0;
        }

        forwardCurrent += 1;
        forwardSteps += 1;
      }

      let backwardSteps = 0;
      let backwardCurrent = Math.round(currentFrame);
      while (backwardCurrent !== targetFrame) {
        if (backwardCurrent === 0) {
          backwardCurrent = timeCompleted;
        }

        backwardCurrent -= 1;
        backwardSteps += 1;
      }

      animationItem.setDirection(forwardSteps < backwardSteps ? 1 : -1);

      const listener = (event: EnterFrameCallbackEvent) => {
        if (Math.round(event.currentTime) === targetFrame) {
          animationItem.pause();

          animationItem.removeEventListener('enterFrame', listener);
        }
      };

      animationItem.play();
      animationItem.addEventListener('enterFrame', listener);

      return () => {
        try {
          animationItem.removeEventListener('enterFrame', listener);
          animationItem.pause();
          // eslint-disable-next-line no-empty
        } catch {}
      };
    }
  }, [targetFrame, animationItem]);

  useEffect(() => {
    if (startInView) {
      if (isInView) {
        animationItem?.play();
      } else {
        animationItem?.pause();
      }
    }
  }, [startInView, animationItem, isInView]);

  return <span ref={ref} className={className} />;
};

export default Lottie;
