import Link from '@uikit/components/Link/Link';
import clsx from 'clsx';
import styles from './PreHeader.module.scss';
import { PreHeaderProps } from './types';

const PreHeader = ({
  audiences = [],
  isFullWidth = false,
  isInverted = false,
  links = [],
}: PreHeaderProps) => {
  const content = (
    <nav
      className={clsx(styles.base, {
        [styles.isInverted]: isInverted,
      })}
    >
      {audiences.map(({ isActive, title, url }) => (
        <a
          className={clsx(styles.link, {
            [styles.isActive]: isActive,
          })}
          href={url}
          key={url}
        >
          {title}
        </a>
      ))}

      {audiences.length > 0 && links.length > 0 && (
        <div className={styles.divider} role="separator" />
      )}

      {links.map((link) => {
        return link.isInternalUrl ? (
          <Link className={styles.link} to={link.url} key={link.title}>
            <span>{link.title}</span>
          </Link>
        ) : (
          <a className={styles.link} href={link.url} key={link.title}>
            <span>{link.title}</span>
          </a>
        );
      })}
    </nav>
  );

  return isFullWidth ? (
    <div
      className={clsx(styles.fullWidthContainer, {
        [styles.isInverted]: isInverted,
      })}
    >
      {content}
    </div>
  ) : (
    content
  );
};

export default PreHeader;
