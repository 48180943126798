import clsx from 'clsx';
import styles from './SmallELogo.module.scss';
import { SmallELogoProps } from './types';

const SmallELogo = ({
  className,
  id = 'small-e-logo',
  solidFill = false,
  ...props
}: SmallELogoProps) => {
  return (
    <svg
      className={clsx(styles.base, className)}
      viewBox="0 0 32 32"
      {...props}
    >
      <defs>
        <linearGradient
          x1="4.02817919%"
          y1="50%"
          x2="100%"
          y2="50%"
          id={`${id}-gradient`}
        >
          <stop stopColor="#909" offset="0%" />
          <stop stopColor="red" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M16 7.801c3.21 0 5.31 1.83 5.543 5.201H9.99c.583-3.029 2.86-5.2 6.01-5.2m-12 7.2c0 6.955 5.102 12.004 11.846 12.004 5.044 0 8.211-1.794 10.967-5.104l-4.165-3.253c-1.29 2.131-4.163 3.477-6.802 3.477-2.932 0-5.572-2.187-6.04-4.992h17.182c.06-.617.117-1.683.117-2.132C27.105 8.44 23.411 3 15.787 3 9.102 3 4 8.104 4 15.002"
        fill={solidFill ? 'currentColor' : `url(#${id}-gradient)`}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default SmallELogo;
