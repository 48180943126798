import Icon from '@uikit/components/Icon/Icon';
import clsx from 'clsx';
import styles from './MainHeaderAddon.module.scss';
import { MainHeaderAddonProps } from './types';

const MainHeaderAddon = ({
  className,
  icon,
  iconBadgeSlot,
  isHidden = false,
  isInverted = false,
  onClick,
  title,
  ...props
}: MainHeaderAddonProps) => {
  const Tag = onClick ? 'button' : 'div';

  return (
    <Tag
      className={clsx(styles.base, className, {
        [styles.isHidden]: isHidden,
        [styles.isInverted]: isInverted,
      })}
      onClick={onClick}
      {...props}
    >
      <div aria-hidden="true" className={styles.iconContainer}>
        <Icon
          className={styles.icon}
          isOutlined
          size="iconSize20"
          variant={icon}
        />
        {iconBadgeSlot && (
          <div className={styles.iconBadge}>{iconBadgeSlot}</div>
        )}
      </div>

      <span className={styles.title}>{title}</span>
    </Tag>
  );
};

export default MainHeaderAddon;
