import config from '@/config';
import { useQuery } from 'react-query';
import fetchSearchSuggestions from './fetchSearchSuggestions';

const useSearchSuggestions = (query: string) =>
  useQuery(
    ['search-suggestions', { query }],
    () => fetchSearchSuggestions(query, config.currentProjectName),
    {
      enabled: !!query,
      placeholderData: [],
      retry: false,
    }
  );

export default useSearchSuggestions;
