import Link from '@uikit/components/Link/Link';
import MainHeader from '../MainHeader/MainHeader';
import styles from './LinkAddon.module.scss';
import { LinkAddonProps } from './types';

const LinkAddon = ({
  icon,
  isHidden,
  isInternalUrl = true,
  isInverted,
  title,
  url,
}: LinkAddonProps) => {
  const content = (
    <MainHeader.Addon
      icon={icon}
      isHidden={isHidden}
      isInverted={isInverted}
      title={title}
    />
  );

  return isInternalUrl ? (
    <Link className={styles.base} to={url}>
      {content}
    </Link>
  ) : (
    <a className={styles.base} href={url}>
      {content}
    </a>
  );
};

export default LinkAddon;
