import clsx from 'clsx';
import styles from './Hamburger.module.scss';
import { HamburgerProps } from './types';

const Hamburger = ({ crossed }: HamburgerProps) => {
  return (
    <div className={styles.base}>
      <div
        className={clsx(styles.element, {
          [styles.crossed]: crossed,
        })}
      />
    </div>
  );
};

export default Hamburger;
