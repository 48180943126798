import Icon from '../Icon/Icon';
import Link from '../Link/Link';
import styles from './CareerLogo.module.scss';

const CareerLogo = () => {
  return (
    <div>
      <Link to="/karriere">
        <div className={styles.base}>Karriere</div>
      </Link>

      <a
        className={styles.sublineContainer}
        href="https://www.enercity.de/"
        title="Zur Startseite"
      >
        <div className={styles.subline}>bei enercity</div>
        <Icon
          className={styles.icon}
          variant="action/external-link"
          size="iconSize18"
        />
      </a>
    </div>
  );
};

export default CareerLogo;
