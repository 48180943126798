import { ProjectName } from '@/config';
import { HeaderVariant } from '@/hooks/useHeader/types';

export const HEADER_LOGO_HEIGHTS: Record<
  ProjectName,
  { desktop: number; mobile: number }
> = {
  enercity: { desktop: 34, mobile: 26 },
  ecg: { desktop: 54, mobile: 38 },
  eeg: { desktop: 54, mobile: 38 },
  eng: { desktop: 64, mobile: 38 },
  esg: { desktop: 54, mobile: 38 },
  esol: { desktop: 54, mobile: 38 },
  'mein-h-gas': { desktop: 63, mobile: 51 },
};

export const HEADER_SHORT_LOGO_VARIANTS: HeaderVariant[] = ['ecg', 'enercity'];
