import config, { endpoints, ProjectName } from '@/config';
import * as ajax from '@/helpers/ajax';

const fetchSearchSuggestions = async (
  query: string,
  projectName: ProjectName
): Promise<string[]> => {
  if (!query) {
    return [];
  }

  const { results } = await ajax.getJson<{ results: string[] }>(
    `${config.api.baseUrl}${endpoints.searchSuggestions}?q=${query}&projectName=${projectName}`
  );

  return results;
};

export default fetchSearchSuggestions;
