import styles from './FocusTrap.module.scss';
import { FocusTrapProps } from './types';

const FocusTrap = ({ isActive, ...props }: FocusTrapProps) => {
  const handleFocus = () => {
    if (props.behavior === 'refocus') {
      props.target?.focus();
    } else if (props.behavior === 'trigger') {
      props.onFocus();
    }
  };

  return (
    <div
      aria-hidden="true"
      className={styles.base}
      onFocus={handleFocus}
      tabIndex={isActive ? 0 : -1}
    />
  );
};

export default FocusTrap;
