import * as ajax from '@/helpers/ajax';
import { logger } from '@/helpers/logger';
import assetPath from '@uikit/helpers/assetPath';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { LottieProps } from '../Lottie/interfaces';
import Lottie from '../Lottie/Lottie';
import styles from './AnimatedIcon.module.scss';
import type { AnimatedIconProps } from './interfaces';

type AnimationData = LottieProps['animationData'];

const getIconJson = (variant: string) =>
  ajax.getJsonCached<AnimationData>(
    `${assetPath}/assets/animated-icons/${variant}.json`
  );

const AnimatedIcon = ({
  variant,
  playOnHover,
  playing,
  className,
  pauseMode,
}: AnimatedIconProps) => {
  const [animationData, setAnimationData] = useState<AnimationData>();
  const [isHoverPlaying, setIsHoverPlaying] = useState(playing);

  useEffect(() => {
    (async () => {
      try {
        const data = await getIconJson(variant);

        if (!data) {
          throw new Error(`Error: no animation data for ${variant}`);
        }

        setAnimationData(data);
      } catch (error) {
        logger.error('Failed to load animationData!', error);
      }
    })();
  }, [variant]);

  return variant && animationData ? (
    <span
      onMouseEnter={() => setIsHoverPlaying(true)}
      onMouseLeave={() => setIsHoverPlaying(false)}
      className={clsx(styles.base, className)}
    >
      <Lottie
        name={variant}
        animationData={animationData}
        loop
        playing={playOnHover ? isHoverPlaying : playing}
        pauseMode={pauseMode}
      />
    </span>
  ) : null;
};

export default AnimatedIcon;
